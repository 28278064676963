import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/salat/salle.med7.fr/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.find.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import Form from 'src/services/form';
import filters from 'src/services/filters';
import date from 'quasar/src/utils/date.js';;
var formatDate = date.formatDate;
export default {
  name: 'ShiftList',
  props: {
    timemachine: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      form: new Form({
        id: null,
        end_at: null
      })
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState({
    ui: function ui(s) {
      return s.auth.ui;
    },
    isAdmin: function isAdmin(s) {
      return s.auth.user.is_admin;
    }
  })), mapGetters({
    pastShifts: 'entities/shifts/pastShifts',
    ongoingShifts: 'entities/shifts/ongoingShifts',
    todaysShifts: 'entities/shifts/todaysMedicalShifts'
  })), {}, {
    index: function index() {
      return this.timemachine ? this.pastShifts : this.todaysShifts;
    },
    shifts: function shifts() {
      return this.index.filter(function (s) {
        return !s.isAssistant();
      });
    },
    assistant: function assistant() {
      var shift = this.index.find(function (shift) {
        return shift.isAssistant();
      });
      return shift ? shift.user : {
        avatarUrl: 'https://ucarecdn.com/afbed8c0-db48-4571-af0f-da29ad375023/',
        fullname: 'Personne'
      };
    },
    reinforcement: function reinforcement() {
      return this.index.find(function (s) {
        return s.type.id === 12;
      });
    },
    shiftEndAt: {
      get: function get() {
        return formatDate(this.reinforcement.end_at, 'HH:mm');
      },
      set: function set(time) {
        this.form.id = this.reinforcement.id;
        this.form.end_at = time;
      }
    },
    authCanSwitch: function authCanSwitch() {
      return this.assistant.id && this.user.id !== this.assistant.id;
    },
    shiftVisibility: function shiftVisibility() {
      var _this = this;
      return this.todaysShifts.reduce(function (acc, shift) {
        acc[shift.id] = _this.ongoingShifts.some(function (some) {
          return some.id === shift.id;
        }) ? 'fal fa-eye' : 'fal fa-eye-slash';
        return acc;
      }, []);
    },
    shiftVisibilityColor: function shiftVisibilityColor() {
      var _this2 = this;
      return function (id) {
        return _this2.ui.visibilities.shifts.some(function (shift) {
          return shift.id === id;
        }) ? 'primary' : 'grey-6';
      };
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapActions('entities/shifts', ['toggleShiftVisibility'])), {}, {
    submit: function submit() {
      this.form.submit('entities/shifts/sync');
      // .then(() => {
      //     console.log('then')
      //     if ('date' in this.$currentRoute.params) {
      //         this.$store.dispatch('entities/visits/fetchByDate', this.$currentRoute.params.date)
      //     } else {
      //         this.$store.dispatch('entities/visits/fetch')
      //     }
      // })
    }
  }),

  filters: filters
};